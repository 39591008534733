
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import dateFormat from "@/utils/dateFormat";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import qs from "qs";
// import Empty from "../common/Empty.vue";
export default defineComponent({
  // components: { Empty },
  name: "Follow",
  props: {
    follow: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props) {
    const nativeData = computed(() => {
      return props.follow.data.slice(0);
    });

    const router = useRouter();
    function followWithInterest(userid: string, index: number) {
      const data = qs.stringify({
        userid,
      });
      axios
        .post("/M/BBSServer/Follow", data)
        .then((res) => {
          console.log(res)
          nativeData.value[index].count = 1;
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
         console.log(err)
        });
    }
    function unFollowWithInterest(userid: string, index: number) {
      const data = qs.stringify({
        userid,
      });
      axios
        .post("/M/BBSServer/CancelFollow", data)
        .then((res) => {
          if (res.data.success) {
            nativeData.value[index].count = 0;
            Toast({
              type: "success",
              title: res.data.msg,
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
         console.log(err)
        });
    }
    function onClick(
      count: number,
      index: number,
      beFollowId: string,
      followId: string
    ) {
      // type : follow 我关注的
      const id = props.follow.type == "follow" ? beFollowId : followId;
      if (count == 0) {
        followWithInterest(id, index);
      } else {
        unFollowWithInterest(id, index);
      }
    }

    return {
      dateFormat,
      onClick,
      nativeData,
    };
  },
});
